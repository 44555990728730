import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import VideoPreview from "../components/VideoPreview"
import HoverButton from "../components/buttons/HoverButton"
import { devices } from "../settings"
import { parseOutVideoId } from "../utilities/utils"

const PageLayout = styled.div`
  display: flex;
  margin-top: 20px;

  @media ${devices.mobile} {
    display: block;
  }  

  > div:nth-child(1) {
    flex-basis: 60%;
  }
  > div:nth-child(2) {
    flex-basis: 40%;  
    padding-left: 15px;    

    @media ${devices.mobile} {
        padding-left: 0px;
    }  

    > div {
        display: flex;

        > * {
            flex-basis: 50%;
        }
    }
    > div:first-child {
        margin-top: -5px;
    }

    h3 {
        margin: 0px;
    }
    a {
        color: black;
    }
  }
`
const VideoContainer = styled.div`
  > div {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe{
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }
`

export default function Videos({ data }) {
    const post = data.video.edges[0].node;
    const videos = data.videos.edges.filter(v => v.video.name != post.name);
    const videoUrl = "https://www.youtube.com/embed/" + parseOutVideoId(post.video_url) + "?autoplay=1";

    return (
        <>
            <SEO page="video" title={post.title} />
            <Page 
                breadcrumb={[
                    { url: "/", title: "Home"},
                    { url: "/why/", title: "Why SignMonkey"},
                    { url: "/why/videos/", title: "Videos"},
                    { url: null, title: post.title }
                ]}
            >
                <PageHeader 
                    title={post.title}  
                />

                <PageLayout>
                    <div>
                        <VideoContainer>
                            <div>
                                <iframe src={videoUrl} width="420" height="315" title={post.title} frameBorder='0' allowFullScreen></iframe>
                            </div>
                        </VideoContainer>

                        {post.description && 
                            <p>{post.description}</p>
                        }
                        {post.summary && 
                            <p dangerouslySetInnerHTML={{ __html: post.summary }} />
                        }
                        {(post.content && post.content.length > 0 && post.content != "<p><br></p>") && 
                            <p>
                                <strong>More information is available on our</strong>
                                &nbsp;
                                &nbsp;
                                <Link to={"/blog/" + post.name + "/"}>
                                    <HoverButton
                                    label="Sign Blog"
                                    />
                                </Link>                                
                            </p>
                        }
                        {(false && post.content) && 
                            <p dangerouslySetInnerHTML={{ __html: post.content }} />
                        }
                    </div>
                    <div>
                        { videos.map((item, index) => (
                            <div key={index}>
                                <VideoPreview 
                                    video_url={"https://www.youtube.com/embed/" + item.video.video}
                                    image_url={item.video.image_url} 
                                    page_url={"/why/videos/" + item.video.name}
                                    size="small"
                                />
                                <div>
                                    <h3>
                                        <Link to={"/why/videos/" + item.video.name + "/"}>
                                            {item.video.title}
                                        </Link>
                                    </h3>
                                    <div>
                                        <small>{item.video.description}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </PageLayout>
            </Page>
        </>
    )
}

export const query = graphql`
    query($name: String!) {
        video: allMysqlBlogPosts(filter: {name: {eq: $name}}) {
            edges {
                node {
                    id
                    name
                    title
                    description
                    summary
                    content
                    keywords
                    added_date
                    preview_url
                    video_url
                }
            }
        }
        videos: allMysqlVideoBlogPosts(sort: {fields: added_date, order: DESC}, limit: 10) {
            edges {
                video: node {
                    id
                    name
                    video_url
                    title
                    description
                    image_url
                    added_date
                }
            }
        }
    }`